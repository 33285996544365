<template>
  <div>
    <v-container fluid>
      <Breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-card flat class="transparent">
        <v-card-text class="text-center">
          <v-avatar tile size="350">
            <v-img :src="require('@/assets/404.svg')"></v-img>
          </v-avatar>
          <h1 class="my-4 text-uppercase">
            OOPS Page non trouvée !
          </h1>
          <p>
            Nous n'avons pas trouvé la page que vous recherchez
          </p>
          <v-btn color="primary"  depressed to="/">Dashboard</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  components: {Breadcrumbs},
  data() {
    return {
      breadcrumbs: [
        {text: 'Dashboard', disabled: false, to: '/'},
        {text: 'Page non trouvée', disabled: false},
      ],
    }
  }
}
</script>

<style scoped>

</style>